import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, snapshotChanges } from 'angularfire2/database';
import { Upload } from '@app/shared/services/storage/storage';
import * as firebase from 'firebase';

@Injectable()
export class StorageService {

  constructor( private db: AngularFireDatabase) { }

  private basePath:string = '/catalog/system_config/company';
  uploads: AngularFireList<Upload[]>;

  pushUpload(upload: Upload) {
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      () => {
      // upload in progress
      upload.progress = uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes * 100
      },
      (error) => {
        // upload failed
        console.log(error)
        return error;
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then( (downloadURL) => {
          console.log('File available at', downloadURL);
          upload.url = downloadURL
          upload.name = upload.file.name
          return this.saveFileData(upload)
        });
      }
    );
  }

  // Writes the file details to the realtime db
  private saveFileData(upload: Upload) {
    this.db.object(`${this.basePath}/`).update({ logoUrl: upload.url });
  }

  deleteUpload(upload: Upload) {
    this.deleteFileData(upload.$key)
    .then( () => {
      this.deleteFileStorage(upload.name)
    })
    .catch(error => console.log(error))
  }

  // Deletes the file details from the realtime db
  private deleteFileData(key: string) {
    return this.db.list(`${this.basePath}/`).remove(key);
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name:string) {
    let storageRef = firebase.storage().ref();
    storageRef.child(`${this.basePath}/${name}`).delete()
  }
}