import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class IncrementalnumberService {

  private basePath: string = 'counters';
  counter: AngularFireObject<any> = null;

  constructor(private db: AngularFireDatabase ) {  }

  getIncrementalCounter(element): AngularFireObject<any> {
    const counterPath = `${this.basePath}/${element}`;
    let counter = this.db.object(counterPath);
    return counter;
  }

  setIncrementalCounter(element) {
    const counterPath = `${this.basePath}/${element}`;
    let counterRef = firebase.database().ref(counterPath).child('counter');

    counterRef.transaction((counter) => {
      if (counter) {
        counter = counter + 1;
      } else {
        counter = 1;
      }
      return counter;
    });
  }

}