export class Item {
  name: string = '';
  time: number = 0;
  services: [Services];
  products: [Products];
  attributes: [Attributes];
  active: boolean = true;
}

export class Services {
  type: string = "";
  quantity: string = '';
  time: string = '';
  price: number = 0;
  active: boolean = true;
}

export class Products {
  type: string = "";
  manufacturer_code: string = '';
  quantity: string = '';
  time: string = '';
  active: boolean = true;
  price?: number = 0;
  notes?: string = "";
  inventory_status?: string = "Solicitado";
  key?: string = "";
}

export class Attributes {
  attribute: string = '';
  value: string = '';
}

export var columnDefs = [
  { headerName: 'Id', field: '$key', hide: true },
  { headerName: "#", checkboxSelection: true, suppressSorting: true },
  { headerName: 'Descripción', field: 'name', editable: true },
  { headerName: 'Horas', field: 'time', editable: true }
]

export var rowGroupPanelShow = "always";