import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireObject, AngularFireDatabase } from 'angularfire2/database';
import { LocaleService, TranslationService, Language } from 'angular-l10n';
import { Location } from '@angular/common';
import { Item } from '@app/shared/models/routes';
import { AssignmentsService } from './assignments.service';
import swal from 'sweetalert2';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class RoutesService {

  @Language() lang: string;

  successMessage: any = {};
  errorMessage: any = {};
  deleteWarning: any = {};

  private basePath: string = 'catalog/routes';

  item: AngularFireObject<Item> = null;
  items: AngularFireList<Item[]> = null;
  assignments;

  constructor( public location: Location, private assignmentsService: AssignmentsService, private db: AngularFireDatabase, public locale: LocaleService, private translation: TranslationService) {
    this.translation.translationChanged().subscribe(
      () => {
        this.successMessage.title = this.translation.translate('SuccessMessage.Title');
        this.successMessage.subtitle = this.translation.translate('SuccessMessage.Subtitle');
        this.errorMessage.title = this.translation.translate('ErrorMessage.Title');
        this.errorMessage.subtitle = this.translation.translate('ErrorMessage.Subtitle');
        this.errorMessage.footer = this.translation.translate('ErrorMessage.Footer');
        this.deleteWarning.title = this.translation.translate('DeleteWarning.Title');
        this.deleteWarning.subtitle = this.translation.translate('DeleteWarning.Subtitle');
      }
    );
  }

  getItemsList(items?: number): AngularFireList<Item> {
    this.items = this.db.list(this.basePath, ref =>
      ref.limitToLast(items || 1000)
    );
    return this.items
  }

  // Return a single observable item
  getItem(key: string): AngularFireObject<Item> {
    const itemPath = `${this.basePath}/${key}`;
    this.item = this.db.object(itemPath)
    return this.item
  }

  pushItem(item): void {
    this.items = this.db.list(this.basePath);
    this.items.push(item)
      .then(response => this.handleSuccess(response))
  }

  updateItem(key: string, value: any): void {
    console.log(key, value);
    const itemPath = `${this.basePath}/${key}`;
    this.item = this.db.object(itemPath);

    this.item.update(value)
      .then(response => {
        this.handleSuccess(response);
        this.updateRouteAssignments(key, value);
      })
      .catch(error => this.handleError(error));
  }

  updateRouteAssignments(key: string, value: any): void {
    let updates = [];
    value.isParentActive = value.active;
    let subscription = this.assignmentsService.getItemsList();
    subscription.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      ),
      take(1)
    ).subscribe(items => {
      let i;
      items.forEach( (day: any) => {
        // console.log(day);
        let dayName = day.key;
        for (i in day) {
          if (typeof day[i] == "object") {
            let array = Object.keys(day[i]);
            array.forEach((customer: any) => {
              if (key == customer) {
                // console.log("This one matches: ", customer);
                console.log("Record is: ", day[i][customer]);
                let assignments = _.map(day[i][customer], (v, key) => ({ key, ...v }))
                assignments.forEach((assignment: any) => {
                  // console.log(assignment);
                  updates[`${dayName}/${assignment.round}/${customer}/${assignment.key}`] = value;
                  // this.assignmentsService.updateFromRouteUpdated(`/${dayName}/${assignment.round}/${customer}/`,assignment.key,value)
                });
              }
            });
          }
        }
      });
      console.log("updates array: ", updates);
      for (var k in updates) {
        if (updates.hasOwnProperty(k)) {
          this.assignmentsService.updateFromRouteUpdated(k, updates[k]);
        }
      }
    });
  }

  updateObject(value: any): void {
    this.item.update(value)
      .then(response => this.handleSuccess(response))
      .catch(error => this.handleError(error));
  }

  deleteItem(key: string, record): void {
    swal({
      title: this.deleteWarning.title,
      text: this.deleteWarning.subtitle,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: `Eliminar ${record.name}`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.items.remove(key || "undefined")
          .then(response => this.handleSuccess(response))
          .catch(error => this.handleError(error));
      }
    })
  }

  // Deletes the entire list of items
  deleteAll(): void {
    this.items.remove()
      .catch(error => this.handleError(error))
  }

  // Default error handling for all actions
  handleError(error) {
    return swal({
      type: 'error',
      title: this.errorMessage.title,
      text: this.errorMessage.subtitle,
      footer: `<a routerlink=["/application/errors"]>${this.errorMessage.footer}</a>`
    })
  }

  handleSuccess(response) {
    return swal({
      timer: 1500,
      type: 'success',
      title: this.successMessage.title,
      text: this.successMessage.subtitle
    }).then( () => {
      this.location.back();
    })
  }
}